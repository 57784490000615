@font-face {
    font-family: 'SF UI Display';
    src: local('SF UI Display'), url(../fonts/SFUIDisplay.woff) format('woff'),
        local('SF UI Display'), url(../fonts/SFUIDisplay.woff) format('woff2');
}
#vocads-widget-root {
    --vocads-primary-color: #f16334 !important;
    --vocads-secondary-color: #96de9e !important;
    --vocads-header-color: #434343 !important;
    --vocads-text-color: #2e2e2e !important;
    --vocads-status-indicator: rgb(191, 191, 191) !important;
    --vocads-animation-color: rgba(241, 99, 52, 0.7) !important;
    /* box-sizing: content-box !important; */
    font-family: 'SF UI Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    line-height: 1.1 !important;
}
#vocads-widget-root * {
    box-sizing: initial !important;
    z-index: 9999 !important;
}

div.vocads-widget-container-main {
    position: fixed !important;
    bottom: 20px !important;
    right: 30px !important;
    color: var(--vocads-text-color) !important;
}

.vocads-widget-container-main > .vocads-widget-button-container,
.vocads-widget-button-container,
.vocads-widget-button-container.vocads-widget-button-minimize {
    position: fixed !important;
    bottom: 20px !important;
    right: 30px !important;
    width: 60px !important;
    height: 60px !important;
}
.vocads-widget-button-container {
    background-color: var(--vocads-primary-color) !important;
    color: white !important;
    padding: 15px !important;
    border-radius: 100px !important;
    display: flex !important;
}
div.vocads-widget-animated-circle {
    width: 30px !important;
    height: 30px !important;
    padding: 15px !important;
    border-radius: 100px !important;
    color: white !important;
    cursor: pointer !important;
    animation: pulse 1.4s infinite !important;
}

.vocads-widget-container-main > .vocads-widget-button-container svg {
    cursor: pointer !important;
}
.vocads-widget-title {
    font-size: 19px !important;
}
div.vocads-widget-content-container-main {
    border-radius: 10px !important;
    width: 30% !important;
    margin-bottom: 70px !important;
    width: 300px !important;
    float: right !important;
    filter: drop-shadow(0 0 5px rgba(2, 0, 6, 0.14));
    overflow: hidden !important;
    text-align: center !important;
}

div.vocads-widget-animated-circle-inner {
    width: 30px !important;
    height: 30px !important;
    padding: 15px !important;
    border-radius: 100px !important;
    color: white !important;
    position: absolute !important;
    animation: pulse 1.4s infinite !important;
    z-index: 999 !important;
}
div.vocads-widget-is-listening div.vocads-widget-animated-circle-inner {
    animation-direction: reverse !important;
    --vocads-animation-color: rgba(105, 223, 121, 0.95) !important;
}
/* Might be able to fix cleanslate animation issue with CSS variables 
https://stackoverflow.com/questions/3012899/is-there-a-box-shadow-color-property
*/
@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 var(--vocads-animation-color);
        -webkit-box-shadow: 0 0 0 0 var(--vocads-animation-color);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

div.vocads-widget-content-header {
    background-color: var(--vocads-header-color) !important;
    color: white !important;
    padding: 15px 0 !important;
}
small.vocads-widget-mic-status {
    color: var(--vocads-status-indicator) !important;
}

div.vocads-widget-content-header h3.vocads-widget-title {
    font-size: 17px !important;
    margin: 0 !important;
    padding: 5px 35px !important;
}
div.vocads-widget-question-container {
    height: 85.5px !important;
    display: flex !important;
    justify-content: center !important;
}
h3.vocads-widget-current-question {
    font-size: 19px !important;
    font-weight: 600 !important;
    padding: 0 !important;
    align-self: center !important;
}
div.vocads-widget-content-header span {
    font-size: 14px !important;
}
div.vocads-widget-content-inner {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    padding: 20px !important;
    padding-bottom: 8px !important;
    padding-top: 30px !important;
    background-color: white !important;
    height: 300px !important;
}
div.vocads-widget-content-inner .vocads-widget-button-container {
    align-self: center !important;
    /* margin-bottom: 20px !important; */
}
div.vocads-widget-button-container svg {
    width: 30px !important;
    height: 30px !important;
    padding: 15px !important;
    border-radius: 100px !important;
    background-color: var(--vocads-primary-color) !important;
    color: white !important;
}

.vocads-widget-hide {
    display: none !important;
}
div.vocads-widget-is-listening svg {
    background-color: var(--vocads-secondary-color) !important;
}
div.vocads-widget-content-footer {
    border-radius: 25px !important;
    display: block !important;
    background-color: #f5f5f5 !important;
    font-size: 11px !important;
    margin: 0 auto !important;
    padding: 11px 0 !important;
    width: 160px !important;
    display: flex !important;
    justify-content: center !important;
    align-content: center !important;
}

a.vocads-widget-footer-link,
a.vocads-widget-footer-link:visited {
    color: var(--vocads-text-color) !important;
    text-decoration: none !important;
    /* margin-top: 20px !important; */
}

div.vocads-widget-ribbon-container {
    position: relative !important;
}
div.vocads-widget-ribbon {
    position: absolute !important ;
    left: -5px !important ;
    top: -5px !important ;
    z-index: 1 !important ;
    overflow: hidden !important ;
    width: 65px !important ;
    height: 65px !important ;
    text-align: right !important ;
}
div.vocads-widget-ribbon span {
    font-size: 10px !important ;
    font-weight: bold !important ;
    color: var(--vocads-status-indicator) !important ;
    text-transform: uppercase !important ;
    text-align: center !important ;
    line-height: 20px !important ;
    transform: rotate(-45deg) !important ;
    -webkit-transform: rotate(-45deg) !important ;
    width: 100px !important ;
    display: block !important ;
    background: #f5f5f5 !important;
    position: absolute !important ;
    top: 19px !important ;
    left: -21px !important ;
}
div.vocads-widget-ribbon span::before {
    content: '' !important ;
    position: absolute !important ;
    left: 0px !important ;
    top: 100% !important ;
    z-index: -1 !important ;
    border-left: 3px solid #b6bac9 !important ;
    border-right: 3px solid transparent !important ;
    border-bottom: 3px solid transparent !important ;
    border-top: 3px solid #b6bac9 !important ;
}
div.vocads-widget-ribbon span::after {
    content: '' !important ;
    position: absolute !important ;
    right: 0px !important ;
    top: 100% !important ;
    z-index: -1 !important ;
    border-left: 3px solid transparent !important ;
    border-right: 3px solid #b6bac9 !important ;
    border-bottom: 3px solid transparent !important ;
    border-top: 3px solid #b6bac9 !important ;
}
div.vocads-widget-content-header svg {
    width: 11px !important;
    margin-bottom: -1px !important;
    margin-right: 5px !important;
}
div.vocads-widget-content-footer > svg {
    /* max-height: 13px !important; */
    /* margin-top: -2px !important; */
}

div.vocads-widget-tooltip,
div.vocads-widget-tooltip > svg {
    border-radius: 100px !important;
}
/* Tooltip container */
.tooltip {
    position: relative !important;
    display: inline-block !important;
    max-height: 60px !important;
}

.vocads-widget-tooltip:hover .vocads-widget-tooltip-text,
.vocads-widget-tooltip:hover::after {
    visibility: visible !important;
}

.vocads-widget-tooltip .vocads-widget-tooltip-text {
    visibility: hidden !important;
    width: 120px !important;
    font-size: 14px !important;
    background-color: var(--vocads-header-color) !important;
    color: #fff !important;
    text-align: center !important;
    padding: 8px !important;
    border-radius: 6px !important;
    /* Position the tooltip text - see examples below! */
    position: absolute !important;
    z-index: 1 !important;
    top: 14px !important;
    right: 100% !important;
    margin-right: 10px !important;
}

.vocads-widget-tooltip::after {
    visibility: hidden !important;
    content: '' !important;
    position: absolute !important;
    left: -10px !important;
    top: 25px !important;
    width: 0 !important;
    height: 0 !important;
    border: 6px solid transparent !important;
    border-left-color: var(--vocads-header-color) !important;
}

div.vocads-widget-coupon-container {
    display: flex !important;
    justify-content: center !important;
    align-content: center !important;
    height: 25px !important;
}

span.vocads-widget-coupon {
    outline: 2px dotted var(--vocads-header-color) !important;
    padding: 3px 10px !important;
    cursor: pointer !important;
}

ul.options-ul {
    display: inline-flex !important;
    justify-content: center !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
}
.options-ul li {
    padding: 5px 10px !important;
    list-style-type: none !important;
    border-radius: 25px !important;
    border: 1px solid var(--vocads-header-color) !important;
    margin: 5px !important;
}
